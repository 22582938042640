<template>
  <page title="我的积分" >
    
    <list>
        <media-item 
          :image="item.image" 
          :title="item.title"  
          img-ratio="16/15"
          img-width="90"
          note="数量：293台"
          class="flex-1"
          v-for="item in TestData.examines" 
          :key="item.id" 

        >
          <div class="text-gray-light mt-1 text-xs">
            订单编号：20202225522222
          </div>
          <div class="text-gray-light mt-1 text-xs">
            日期：2020-12-12
          </div>
          <div class="flex items-center justify-between mt-1" >
            <div class="text-red-600 font-medium"><span class="text-xs">¥</span>89.9</div>
            <div class="text-gray-light text-xs">
              <span>积分:199分</span>
              <span class="ml-3">余额:434元</span>
            </div>
          </div>
        </media-item>
      </list>
    <div class="h-12 pl-4 bg-gray-50 relative hairline-top  flex items-center" slot="footer">
      <div class="flex-1 flex items-center ">
        积分额度：<span class="text-lg font-medium text-red-500">3920.00 分</span>
      </div>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    
    goPay(){
      this.$router.push('/paid')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>